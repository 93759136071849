<template>
  <div class="loader">
    <v-progress-circular
      :size="100"
      :width="3"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return {
      interval: {},
      value: 0,
    };
  },
  methods: {
    clearIntervalMethod() {
      this.value = 100;
      clearInterval(this.interval);
    },
  },
  beforeDestroy() {
    this.clearIntervalMethod();
  },
  mounted() {
    this.interval = setInterval(() => {
      if (100 - this.value >= 20) {
        this.value += parseInt(Math.random().toFixed(1) * 20);
      }
    }, 1000);
  },
};
</script>

<style src="./Loader.scss" lang="scss" scoped></style>
